$gray-100: #fafbfc !default;
$gray-150: #f5f7fa !default;
$gray-200: #ebecf1 !default;
$gray-300: #d1d8dd !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8d99a6 !default;
$gray-700: #495057 !default;
$gray-800: #36414c !default;
$gray-900: #2e3338 !default;
$primary: #5e64ff !default;

$black: #000 !default;

$body-color: $gray-800 !default;
$text-muted: $gray-600 !default;
$border-color: $gray-200 !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

