@import "variables";

img {
	position: relative;
}

// fallback for broken images
img:after {
	content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='lightgrey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-image'><rect x='3' y='3' width='18' height='18' rx='2' ry='2'/><circle cx='8.5' cy='8.5' r='1.5'/><polyline points='21 15 16 10 5 21'/></svg>");
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $light;
	padding: 50% 0;
}

.standard-image {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	border: 1px solid $border-color;
	width: 1.5rem;
	height: 1.5rem;
}

.website-image-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	color: $gray-300;
	background: $light;
}

.website-image-lazy {
	min-height: 200px;
	height: 100%;
	background-color: $light;
}

@mixin website-image {
	display: inline-block;
	object-fit: contain;
}

.website-image {
	@include website-image;
	width: 100%;
}

.website-image-small {
	@include website-image;
	width: 5rem;
	height: 5rem;
}

.website-image-medium {
	@include website-image;
	width: 10rem;
	height: 10rem;
}

.website-image-large {
	@include website-image;
	width: 15rem;
	height: 15rem;
}

.website-image-extra-large {
	@include website-image;
	width: 30rem;
	height: 30rem;
}

.website-image[src*="no-image.jpg"] {
	padding: 46% 0;
}

.object-fit-cover {
	object-fit: cover;
}