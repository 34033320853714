@import "variables.less";

.indicator,
.indicator-right {
    background:none;
    vertical-align:middle;
    font-size: 12px;
    font-weight:bold;
    color:#6c7680;
}

.indicator::before,
.indicator-right::after {
    content:'';
    display:inline-block;
    height:8px;
    width:8px;
    border-radius:8px;
}

.indicator::before {
	margin:0px 4px 0px 0px;
}

.indicator-right::after {
	margin:0 0 0 4px;
}

.indicator.grey::before,
.indicator-right.grey::after {
    background: @btn-bg;
}
.indicator.blue::before,
.indicator-right.blue::after {
    background: @indicator-blue;
}
.indicator.red::before,
.indicator-right.red::after {
    background: @indicator-red;
}
.indicator.green::before,
.indicator-right.green::after {
    background: @indicator-green;
}
.indicator.orange::before,
.indicator-right.orange::after {
    background: @indicator-orange;
}
.indicator.purple::before,
.indicator-right.purple::after {
    background: @indicator-purple;
}
.indicator.darkgrey::before,
.indicator-right.darkgrey::after {
    background: @indicator-darkgrey;
}

.indicator.black::before,
.indicator-right.black::after {
    background: @text-color;
}

.indicator.yellow::before,
.indicator-right.yellow::after {
    background: @indicator-yellow;
}

.indicator.light-blue::before,
.indicator-right.light-blue::after {
    background: @indicator-light-blue;
}

.indicator.lightblue::before,
.indicator-right.lightblue::after {
    background: @indicator-light-blue;
}

.modal-header .indicator {
	margin-top: 7.5px;
	margin-right: 3px;
}

.indicator.blink {
	animation: blink 1s linear infinite;
}

@keyframes blink {
	50% { opacity: 0.5; }
}